import React, { ReactNode } from 'react';
import { Spinner } from 'react-bootstrap';

interface ButtonProps {
  onClick: () => void;
  className?: string;
  children?: ReactNode;
  disabled?: boolean;
  loading?: boolean;
}

const Button: React.FC<ButtonProps> = ({
  onClick,
  className,
  children,
  disabled,
  loading,
  ...rest
}) => {
  return (
    <button
      className={`${disabled && 'disable-btn'} app-btn ${className} `}
      onClick={onClick}
      disabled={disabled || loading}
      {...rest}
    >
      {loading ? (
        <>
          {children} <Spinner size='sm' />
        </>
      ) : (
        children
      )}
    </button>
  );
};

export default Button;
