import { RENDER_TYPES, VALUE_TYPES } from './constants';
import { Option, OptionRemoveArg, OptionUpdateArg, Question, RenderType, ValueType } from './type';

export const findQuestion = (
  questionSet: Question[],
  id?: number | undefined,
  predicate?: undefined | ((question: Question) => boolean),
): Question | null => {
  if (predicate == undefined) {
    predicate = (q: Question) => q.id == id;
  }

  let foundQuestion: Question | null;

  for (const question of questionSet) {
    if (predicate(question)) {
      return question;
    }
    if (question.childQuestions?.length ?? 0 > 0) {
      foundQuestion = findQuestion(question.childQuestions as Question[], id, predicate);
      if (foundQuestion) {
        return foundQuestion;
      }
    }
  }

  return null;
};

export const updateQuestionFields = (
  questionSet: Question[],
  id: number,
  updates: Partial<Question>,
): Question[] => {
  return questionSet.map((question) => {
    if (question.id === id) {
      return { ...question, ...updates };
    }

    if (question.childQuestions?.length) {
      return {
        ...question,
        childQuestions: updateQuestionFields(question.childQuestions, id, updates),
      };
    }

    return question;
  });
};

export const updateOptionText = (
  questionSet: Question[],
  optionUpdateArg: OptionUpdateArg,
): Question[] => {
  return questionSet.map((question) => {
    if (question.id === optionUpdateArg.questionId) {
      return {
        ...question,
        options: question.options?.map((option) => {
          if (option.id === optionUpdateArg.optionId) {
            return {
              ...option,
              textBangla: optionUpdateArg.optionText,
              textEnglish: optionUpdateArg.optionText,
            };
          }
          return option;
        }),
      };
    }

    if (question.childQuestions?.length) {
      return {
        ...question,
        childQuestions: updateOptionText(question.childQuestions, optionUpdateArg),
      };
    }

    return question;
  });
};

export const getMeta = (renderType: RenderType, isComputed?: boolean): string => {
  let metaJson = {};
  switch (renderType) {
    case RENDER_TYPES.WEIGHTED_STAR_SECTION:
      metaJson = { scale: 5, weight: 1, shouldHideStar: false };
      break;
    case RENDER_TYPES.RADIO:
    case RENDER_TYPES.DROPDOWN:
      if (isComputed) {
        metaJson = { weight: 1 };
      }
      break;
    case RENDER_TYPES.TEXT:
      metaJson = { isRequired: false, type: 'text', regEx: '' };
      break;
    default:
      break;
  }
  return JSON.stringify(metaJson);
};

export const getNewQuestion = (type: RenderType, isComputed?: boolean): Question => {
  const randomId = Date.now();
  let valueType: ValueType = VALUE_TYPES.NONE;

  switch (type) {
    case RENDER_TYPES.DROPDOWN:
    case RENDER_TYPES.RADIO:
      valueType = VALUE_TYPES.OPTION_ID;
      break;

    case RENDER_TYPES.CHECKBOX_LIST:
      valueType = VALUE_TYPES.MULTIPLE_OPTION_ID;
      break;

    case RENDER_TYPES.YES_NO:
    case RENDER_TYPES.CHECKBOX:
      valueType = VALUE_TYPES.BOOLEAN;
      break;

    case RENDER_TYPES.SECTION:
      valueType = isComputed ? VALUE_TYPES.NUMBER : VALUE_TYPES.NONE;
      break;

    case RENDER_TYPES.WEIGHTED_STAR_SECTION:
    case RENDER_TYPES.COMPUTED_STAR:
      valueType = VALUE_TYPES.NUMBER;
      break;

    case RENDER_TYPES.TEXT:
      valueType = VALUE_TYPES.STRING;
      break;

    default:
      throw new Error(`Unknown render type: ${type}`);
  }

  return {
    valueType,
    childQuestions: [],
    id: randomId,
    order: 1,
    isDeletable: true,
    meta: getMeta(type, isComputed),
    renderType: type,
    textBangla: '',
    textEnglish: '',
    helpTextBangla: '',
    helpTextEnglish: '',
  };
};

export const addQuestion = (
  questionSetCopy: Question[],
  parentQuestionId: number,
  newQuestion: Question,
): Question[] => {
  return questionSetCopy.map((question) => {
    if (question.id === parentQuestionId) {
      return {
        ...question,
        childQuestions: [...(question.childQuestions || []), newQuestion],
      };
    }

    if (question.childQuestions?.length) {
      return {
        ...question,
        childQuestions: addQuestion(question.childQuestions, parentQuestionId, newQuestion),
      };
    }

    return question;
  });
};

export const addOption = (
  questionSetCopy: Question[],
  questionId: number,
  newOption: Option,
): Question[] => {
  return questionSetCopy.map((question) => {
    if (question.id === questionId) {
      let option: Option[] = [];
      if (question.options) {
        option = [...question.options];
      }
      option.push(newOption);
      return {
        ...question,
        options: [...option],
      };
    }

    if (question.childQuestions?.length) {
      return {
        ...question,
        childQuestions: addOption(question.childQuestions, questionId, newOption),
      };
    }

    return question;
  });
};

export const removeQuestionFromSet = (questionSet: Question[], idToRemove: number): Question[] => {
  const result = questionSet
    .map((question) => {
      if (question.childQuestions?.length) {
        question.childQuestions = removeQuestionFromSet(question.childQuestions, idToRemove);
      }
      return question;
    })
    .filter((question) => question.id !== idToRemove);
  return result;
};

export const removeOptionFromSet = (
  questionSet: Question[],
  optionToRemove: OptionRemoveArg,
): Question[] => {
  const result = questionSet.map((question) => {
    if (question.id === optionToRemove.questionId) {
      return {
        ...question,
        options: question.options?.filter((option) => option.id !== optionToRemove.optionId),
      };
    }

    if (question.childQuestions?.length) {
      question.childQuestions = removeOptionFromSet(question.childQuestions, optionToRemove);
    }
    return question;
  });

  return result;
};
