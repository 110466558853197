import React from 'react';

import { authInfo } from '../../pages/auth/authSlice';
import { useSelector } from 'react-redux';

const PermissionWrapper = ({ children, permissionName, fallback }) => {
  const {
    user: { activeJurisdiction },
  } = useSelector(authInfo);

  if (!Array.isArray(permissionName)) {
    permissionName = [permissionName];
  }

  if (activeJurisdiction.permissions && permissionName.some(p => activeJurisdiction.permissions.includes(p)))
    return <>{children}</>;
  return <>{fallback}</>;
};

export default PermissionWrapper;
