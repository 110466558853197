import { RenderType, ValueType } from './type';

export const VALUE_TYPES: { [key in ValueType]: ValueType } = {
  NONE: 'NONE',
  NUMBER: 'NUMBER',
  OPTION_ID: 'OPTION_ID',
  MULTIPLE_OPTION_ID: 'MULTIPLE_OPTION_ID',
  STRING: 'STRING',
  BOOLEAN: 'BOOLEAN',
};

export const RENDER_TYPES: { [key in RenderType]: RenderType } = {
  SECTION: 'SECTION',
  WEIGHTED_STAR_SECTION: 'WEIGHTED_STAR_SECTION',
  DROPDOWN: 'DROPDOWN',
  RADIO: 'RADIO',
  TEXT: 'TEXT',
  COMPUTED_STAR: 'COMPUTED_STAR',
  CHECKBOX: 'CHECKBOX',
  CHECKBOX_LIST: 'CHECKBOX_LIST',
  COMPUTED_CHECKBOX_LIST: 'COMPUTED_CHECKBOX_LIST',
  YES_NO: 'YES_NO',
};
